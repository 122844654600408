<template>
  <div id="view-site-3d-visualisation" class="mt-3 pt-3 pb-3 has-iframe">
    <div class="text-right px-4 px-md-0 pb-4">
      <button
        class="btn btn-sm btn-primary rounded-pill px-3 font-weight-medium"
        @click="openModelModal(true)"
      >
        + {{ site && site.model_url ? "Edit URL" : "Add URL" }}
      </button>
    </div>
    <div
      class="content-item overflow-hidden"
      :class="{
        'px-sm-0 py-0': site && site.model_url,
        'd-none': !site || !site.model_url,
      }"
    >
      <iframe
        v-if="site && site.model_url"
        :src="site.model_url"
        frameborder="0"
        class="d-block w-100 rounded"
        @load="toggleSpinner(false)"
      ></iframe>
    </div>
    <p
      class="mb-0 text-center text-muted content-item font-weight-medium"
      v-if="!site || !site.model_url"
    >
      No data
    </p>
    <modal
      id="modelUrlModal"
      v-if="showModal.modelUrl"
      :title="`${site && site.model_url ? 'Edit' : 'Add new'} url`"
      @toggleSpinner="toggleSpinner"
      @close="openModelModal"
    >
      <site-url-form id="model_url" :site="site" label="3D Visualisation" />
    </modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal";
import SiteUrlForm from "@/components/forms/SiteUrl";

export default {
  name: "site-3d-visualisation-view",
  props: ["site"],
  components: {
    Modal,
    SiteUrlForm,
  },
  data() {
    return {
      showModal: {
        modelUrl: false,
      },
    };
  },
  methods: {
    openModelModal(result) {
      if (typeof result == "boolean") {
        this.showModal.modelUrl = result;
      } else if (typeof result == "object") {
        this.showModal.modelUrl = result.show;

        if (result.data) {
          this.$parent.updated("site", result.data);
        }
      }
    },
    toggleSpinner(e) {
      this.$emit("toggleSpinner", e);
    },
  },
  mounted() {
    if (this.site && this.site.model_url) {
      this.toggleSpinner(true);
    }
  },
};
</script>